<template>
  <div class="footer">
    <!-- 描述 -->
    <div class="describe">
      <ul class="describe-list container">
        <li class="describe-item">
          <img src="@/assets/images/footer-logo1.png" alt="" />
          <div class="des-title">权威数据、覆盖全面</div>
          <div>直连权威数据源，数据真实可靠实时更新</div>
        </li>
        <li class="describe-item">
          <img src="@/assets/images/footer-logo2.png" alt="" />
          <div class="des-title">智能路由、准确率高</div>
          <div>智能路由规则、动态分配通道，验证准确率高于同行业平台</div>
        </li>
        <li class="describe-item">
          <img src="@/assets/images/footer-logo3.png" alt="" />
          <div class="des-title">便捷接入、秒级响应</div>
          <div>系统分布式部署，专线联通各数据源</div>
        </li>
        <li class="describe-item">
          <img src="@/assets/images/footer-logo4.png" alt="" />
          <div class="des-title">信息安全、有效风控</div>
          <div>金融级安全要求，用户数据全脱敏，确保敏感信息无泄漏</div>
        </li>
      </ul>
    </div>
    <div class="container">
      <!-- footer info -->
      <div class="ui footer-main pt-40">
        <div class="f1 footer-info">
          <img class="footer-logo" src="@/assets/images/footer-logo.png" alt="" />
          <p>地址：四川省成都市高新区天府新谷10号楼2301</p>
          <p>电话：19182082426</p>
          <p>邮箱：marketing@zmkjcd.onaliyun.com</p>
          <p>友情链接：</p>
        </div>
        <OutLink class="mobile-outlink"></OutLink>
        <div class="f1 ui footer-link">
          <ul class="f1">
            <li class="title">关于中码</li>
            <li><router-link to="/about" class="link">了解我们</router-link></li>
            <li class="link" @click="userFileShow('中码服务协议')">中码服务协议</li>
            <li class="link" @click="userFileShow('隐私政策')">隐私政策</li>
          </ul>
          <ul class="f1">
            <li class="title">产品中心</li>
            <li><router-link to="/product" class="link">全部产品</router-link></li>
            <!-- <li>开发者中心</li> -->
          </ul>
          <ul class="f1">
            <li class="title">权威认证</li>
            <li class="link" @click="showModal">工商营业执照</li>
          </ul>
        </div>
        <div class="ui footer-code">
          <div class="f1">
            <img class="hvr-bounce-in" src="@/assets/images/footer-gzh.png" alt="" />
            <div>微信公众号</div>
          </div>
          <div class="f1">
            <img class="hvr-bounce-in" src="@/assets/images/footer-applet.png" alt="" />
            <div>微信小程序</div>
          </div>
        </div>
      </div>
      <OutLink class="pc-outlink"></OutLink>
      <!-- copyright -->
      <div class="copyright">
        <span>版权所有：中码科技发展（成都）有限公司</span>
        <span class="ml-50"
          >备案号：<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2021029221号-1</a></span
        >
      </div>
    </div>

    <!-- 工商营业执照弹框 -->
    <a-modal
      v-model:visible="visible"
      width="1000px"
      title="工商营业执照"
      centered
      :footer="modalFooter"
    >
      <div>
        <img class="modal-img" src="../assets/images/home-yyzz.png" alt="" />
      </div>
    </a-modal>

    <!-- 隐私政策 -->
    <a-modal
      v-model:visible="userVisible"
      :title="modalShowTitle"
      width="100%"
      wrap-class-name="full-modal"
      centered
      destroyOnClose
      :footer="modalFooter"
    >
      <div class="user-wrap">
        <UserFile :modalShowTitle="modalShowTitle"></UserFile>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import UserFile from "@/components/userfile.vue";
import OutLink from "./out-link.vue";

export default defineComponent({
  components: { UserFile, OutLink },
  setup() {
    let visible = ref(false); //营业执照弹框
    let userVisible = ref(false); //隐私政策弹框
    let modalShowTitle = ref("中码服务协议");

    const userFileShow = (title) => {
      userVisible.value = true;
      modalShowTitle.value = title;
    };

    const showModal = () => {
      visible.value = true;
    };

    const handleCancel = () => {
      visible.value = false;
      userVisible.value = false;
    };

    let modalFooter = [
      <a-button key="back" onClick={handleCancel}>
        关闭
      </a-button>,
    ];

    return {
      visible,
      showModal,
      modalFooter,
      userVisible,
      userFileShow,
      modalShowTitle,
    };
  },
});
</script>

<style lang="less" scoped>
.footer {
  background-color: #06031e;
  color: #999999;
  .mobile-outlink {
    display: none;
  }
  .describe {
    background-color: #f6f9ff;
    padding: 55px 0;
    .describe-list {
      display: flex;
      justify-content: space-between;
      .describe-item {
        width: 23%;
        font-size: 15px;
        color: rgba(20, 45, 112, 0.5);
        img {
          width: 40px;
          height: 40px;
          margin: 0 auto;
          display: block;
        }
        .des-title {
          font-size: 18px;
          color: #fff;
          font-weight: 600;
          text-align: center;
          margin-top: 18px;
          color: #142d70;
        }
      }
    }
  }
  .pt-40 {
    padding-top: 40px;
  }
  .footer-info {
    color: #99a8c1;
    font-size: 13px;
    .footer-logo {
      width: 170px;
      height: 38px;
      margin-bottom: 20px;
    }
  }
  .footer-link {
    font-size: 10px;
    color: #9fadc5;
    line-height: 3;
    .title {
      font-size: 13px;
      color: #fff;
    }
    .link {
      color: #9fadc5;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: #7c83e2;
      }
    }
  }
  .footer-code {
    text-align: center;
    font-size: 10px;
    color: #9fadc5;
    > div {
      margin-left: 100px;
    }
    img {
      width: 110px;
      height: 110px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  .copyright {
    font-size: 10px;
    color: #778899;
    padding: 30px 0 20px;
    .ml-50 {
      margin-left: 50px;
    }
    a {
      color: #778899;
    }
  }
}
.modal-img {
  width: 100%;
  margin: 0 auto;
  display: block;
}
</style>

<style lang="less" scoped>
.mobile {
  .footer {
    .describe {
      .describe-list {
        flex-wrap: wrap;
        padding: 0 20px;
        .describe-item {
          width: 48%;
          margin-bottom: 20px;
          font-size: 14px;
          text-align: center;
          .des-title {
            font-size: 16px;
          }
        }
      }
    }
    .footer-main {
      display: block;
      padding: 40px 20px;
    }
    .mobile-outlink {
      display: block;
    }
    .pc-outlink {
      display: none;
    }
    .footer-link {
      font-size: 13px;
    }
    .footer-code {
      margin-top: 30px;
      > div {
        margin: 0;
      }
    }
    .copyright {
      padding: 0 20px 20px 20px;
      font-size: 13px;
      .ml-50 {
        display: block;
        margin: 0;
      }
    }
  }
}
</style>
>
